import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PanelGreen, PanelBlue, PanelYellow, PanelWhite, PanelRed } from "../components/ContentPanel"
import styled from "styled-components"
import ShapeTriangle from "../components/shapes/Triangle"
import ShapeRectangle from "../components/shapes/Rectangle"
import ShapeCrescent from "../components/shapes/Crescent"
import Quote from "../components/Quote"

const IntroShape = styled.div`
  position: absolute;
  left: -30px;
  bottom: 0;
  display: none;

  @media (min-width: 650px) {
    display: block;
  }

  @media (min-width: 1260px) {
    bottom: 30%;
  }
`

const StartShape = styled.div`
  position: absolute;
  right: -50px;
  bottom: 80%;
  display: none;

  @media (min-width: 650px) {
    display: block;
  }  

  @media (min-width: 1260px) {
    bottom: 70%;
  }
`

const GetInvolvedShape = styled.div`
  position: absolute;
  left: -50px;
  bottom: 90%;
  transform: rotate(-45deg);
  display: none;

  @media (min-width: 650px) {
    display: block;
  }  
`

const AboutShape = styled.div`
  position: absolute;
  right: -150px;
  bottom: 72%;
  transform: rotate(-45deg);
  display: none;

  @media (min-width: 650px) {
    display: block;
  }    
`

const JumboTitle = styled.h2`
  color: ${({ theme }) => theme.colorResetBlue};
  font-size: 3rem ;
  margin-bottom: 3rem;
  @media (min-width: 350px) {
    font-size: 3.8rem ;
    margin-bottom: 3.8rem;
  }    
  @media (min-width: 450px) {
    font-size: 4.8rem ;
    margin-bottom: 4.8rem;
  }    
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colorResetBlue};
  font-size: ${({ theme }) => theme.FontLarge};
  margin-bottom: 2.8125rem;
`

const Statment = styled.p`
  color: ${({ theme }) => theme.colorResetBlue};
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.3;
`

const Copy = styled.p`
  font-size: ${({ theme }) => theme.FontMedium};
  line-height: 1.3;
`

const TitleYellow = styled.h2`
  color: ${({ theme }) => theme.colorResetYellow};
  font-size: ${({ theme }) => theme.FontLarge};
  margin-bottom: 2.8125rem;
`

const StatmentWhite = styled.p`
  color: ${({ theme }) => theme.colorResetWhite};
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.3;
`
const CopyWhite = styled.p`
  color: ${({ theme }) => theme.colorResetWhite};
  font-size: ${({ theme }) => theme.FontMedium};
  line-height: 1.3;
`

const ListWhite = styled.ul`
  color: ${({ theme }) => theme.colorResetWhite};
  font-size: ${({ theme }) => theme.FontMedium};
  line-height: 1.3;
`

const PanelBlueContainer = styled.div`
  padding: 0 20% 0 0;
  text-align:left;
`

const HorizontalRow = styled.hr`
  background-color: ${({ theme }) => theme.colorResetYellow};
  height: 0.15rem;
  margin: 4rem 0 1rem 0;
`

const IndexPage = () => (

  <Layout>

    <SEO title="Imagine the world to come" description="Reset is a chance for your voice to be heard, so that you can help shape a fairer, greener, future for the UK" />

    <PanelGreen>
      <JumboTitle>A Living Manifesto</JumboTitle>

      <Quote
        quote="I hope we will have learned from this crisis as a country, and I want things to change as a result"
        author="4 in 5 people in the UK"
      />

      <IntroShape>
        <ShapeTriangle />
      </IntroShape>
    </PanelGreen>

    <PanelWhite>
      <Title>During lockdown, everything changed. So, what now?<br />Can we go back to the way things were before? Do we want to?</Title>
      <Statment>4 in 5 of people say no.</Statment>
      <Copy><b>So, what changes do we want to make?</b></Copy>
      <Copy>How we answer this question will affect the daily lives of everyone for generations to come.</Copy>
      <Copy>We've spent the last year in conversation with people – over 55,000 people – of all ages, from all walks of life, in every corner of the country, to discover what they – what we – want.</Copy>

      <Title>We don't agree on everything, but we're less divided than we think</Title>
      <Copy>Most of us want things to change. And most want a fairer and greener future for Britain. But what does that mean and how should we achieve it? </Copy>
      <Copy>Opinions vary, but on some things there's an overwhelming consensus.</Copy>
      <Copy>These – and only these – are what the Living Manifesto is built on. Not on grand political visions.</Copy>
      <Copy>Just on the things we all agree we want.</Copy>

      <StartShape>
        <ShapeRectangle />
      </StartShape>
    </PanelWhite>


    <PanelYellow>
      <Statment>We don’t want a revolution.<br /> We want a decent life.</Statment>
    </PanelYellow>

    <PanelBlue>
      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>1. A DECENT JOB</TitleYellow>
        <CopyWhite>Lockdown showed us that unemployment is a political choice. With so much work to be done, it’s time to create jobs for public good not just private profit.</CopyWhite>
        <CopyWhite><b>Two thirds of people support a jobs guarantee and over 90% want better pay and conditions for NHS and care workers </b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>a good jobs guarantee</li>
          <li>a national care service</li>
          <li>a national nature service</li>
          <li>a comprehensive reskilling program</li>
          <li>investment in green infrastructure like cycleways, walkways, green spaces and warm homes</li>
        </ListWhite>
      </PanelBlueContainer>

      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>2. A DECENT LIVING</TitleYellow>
        <CopyWhite>In one of the richest societies in history, no one should be living in the fear of poverty. We want to end poverty by sharing prosperity.</CopyWhite>
        <CopyWhite><b>59% of people support some form of universal basic income or minimum guaranteed income</b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>a minimum income guarantee</li>
          <li>a universal basic income</li>
          <li>giving workers a voice on company boards</li>
        </ListWhite>
      </PanelBlueContainer>

      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>3. A DECENT HOME</TitleYellow>
        <CopyWhite>At the height of the pandemic almost no one was sleeping rough or forced out of their homes. So we know it’s possible. We want everyone to feel safe in the knowledge that they have a decent and affordable home to go to.</CopyWhite>
        <CopyWhite><b>Two thirds of people support rent controls in the private rental sector and 83% want more social housing </b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>rent controls </li>
          <li>secure long-term tenancy</li>
          <li>investment in social housing</li>
        </ListWhite>
      </PanelBlueContainer>

      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>4. A DECENT NEIGHBOURHOOD</TitleYellow>
        <CopyWhite>During lockdown we became better neighbours – and we’re all the better for it. We want to redesign the way we live and work to give us more time to connect and contribute to our local communities.</CopyWhite>
        <CopyWhite><b>4 in 5 people took on some kind of community role during lockdown </b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>15-minute neighbourhoods, where everything we need can be accessed within a 15 minute walk or cycle ride of our homes</li>
          <li>flexible working and a shorter working week</li>
          <li>investment in cycling infrastructure and public transport</li>
          <li>working towards a ‘doughnut economy’ that prioritises the wellbeing of people and the environment</li>
        </ListWhite>
      </PanelBlueContainer>

      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>5. DECENT FOOD</TitleYellow>
        <CopyWhite>With supermarket shelves suddenly empty, many of us experienced the dread of food insecurity for the first time. For many others, not having enough to eat has long been a part of daily life. We want a healthy diet to be available and affordable for everyone – and for everyone to understand what exactly a healthy diet might be.</CopyWhite>
        <CopyWhite><b>70% of people want food to be produced more ethically and sustainably, and 61% want more of our food produced in the UK </b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>investment in public health and education</li>
          <li>more support for UK farmers</li>
          <li>price incentives for production and purchase of healthy products</li>
          <li>policy focus on health, resilience and sustainability rather than efficiency and profit</li>
        </ListWhite>
      </PanelBlueContainer>

      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>6. DECENT TRANSPORT</TitleYellow>
        <CopyWhite>When the cars, trucks and planes switched off their engines all at once, we discovered a quieter place, with cleaner air and a slower pace of life. We want to design our working lives and living spaces to reduce the time and energy we waste on needless and polluting travel</CopyWhite>
        <CopyWhite><b>71% of people noticed less traffic during lockdown and 43% noticed cleaner air. Two thirds want to keep reduced traffic levels and 78% of people support changes to transport systems overall</b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>investment in walking and cycling infrastructure</li>
          <li>improvements and investment in bus and rail infrastructure</li>
          <li>clean air zones</li>
        </ListWhite>
      </PanelBlueContainer>

      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>7. A FUTURE FOR OUR PLANET</TitleYellow>
        <CopyWhite>Governments around the world mobilised with astonishing speed and spent unprecedented sums to tackle Covid – a global crisis that transcended borders, class, race and politics. We want a response of similar proportions to the unfolding environmental crisis now that we know it can be done.</CopyWhite>
        <CopyWhite><b>4 in 5 people support the prioritisation of public health and wellbeing over economic growth.</b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>invest now in the transformation of the UK so that it is greener and fairer, creating good, well-paid jobs across the nations in the process</li>
          <li>lead the development of a Fossil Fuel Non-Proliferation Treaty</li>
          <li>align trade and investment policy to progress on climate</li>
          <li>replace GDP with measures that track the health of people and the environment</li>
        </ListWhite>
      </PanelBlueContainer>


      <HorizontalRow />
      <PanelBlueContainer>
        <TitleYellow>8. MEANINGFUL POLITICS</TitleYellow>
        <CopyWhite>We've lost faith in a system of politics where a Westminster clique makes the big decisions affecting our social and economic lives. We want more power devolved to local government, more say in running our local economies and managing our local environments, and better access to the funds we need to strengthen our communities.</CopyWhite>
        <CopyWhite><b>More than 60% of people think that problems are best solved locally and that local councils should get a bigger share of public money and more control over how to spend it.</b></CopyWhite>
        <CopyWhite>That means:</CopyWhite>
        <ListWhite>
          <li>the devolution of decision making to local democracies</li>
          <li>funding for local transition strategies</li>
          <li>the decentralisation of government procurement</li>
          <li>strengthening local community finance including community venture funds and regional mutual banks</li>
        </ListWhite>
      </PanelBlueContainer>
    </PanelBlue>

    <PanelRed>
      <StatmentWhite>Change is not just possible,<br />it’s inevitable.</StatmentWhite>
      <GetInvolvedShape>
        <ShapeCrescent />
      </GetInvolvedShape>
    </PanelRed>

    <PanelWhite>
      <Copy>Every crisis brings change in its wake, for better or for worse, whether we like it or not. But with change there is always opportunity.</Copy>
      <Copy>The question is: opportunity for whom? </Copy>
      <Copy>This time, everyone should benefit from the changes born of crisis – not just the small wealthy elite. </Copy>
      <Copy>The Living Manifesto provides a starting point – an agenda for change, arrived at by consensus, supported by the majority, and already proven possible.</Copy>

      <Statment>By disrupting our everyday lives, Covid has disrupted our assumptions – about what's inevitable and what's not.</Statment>
      <Statment>Now that we've seen how a better life is possible, it won't be easy to make us unsee it.</Statment>

      <AboutShape>
        <ShapeCrescent />
      </AboutShape>
    </PanelWhite>

    <PanelGreen>
      <Title>Why “Living Manifesto”?</Title>
      <Copy>Because it’s about living, not dreaming. It’s a consensus for change, arrived at by listening to tens of thousands of people describing their lived experience of the Covid crisis, the lessons they’ve learned and the practical changes they want to see in their lives.</Copy>
      <Copy>And because it’s a living document that will change over time. It's not written in stone. It's not even written on paper. Yesterday’s best ideas are useless today; the same will be true of today’s best ideas tomorrow. A Living Manifesto has to grow, change and adapt to flourish – just like every other living thing.</Copy>

      <IntroShape>
        <ShapeTriangle />
      </IntroShape>
    </PanelGreen>

    <PanelYellow>
      <Quote
        quote="The ultimate hidden truth of the world is that it is something we make. And could just as easily make differently."
        author="David Graeber"
      />
    </PanelYellow>

  </Layout>
)

export default IndexPage
