import React from "react"
import styled from "styled-components"
import shapeWhite from "../images/background_shape_white.png"

const Wrap = styled.div`
  background-position: 50%;
  padding: ${({ theme }) => theme.PadPanel};
  position: relative;
`
const Content = styled.div`
  max-width: 56.25rem;
  margin: auto;
  text-align: center;
`

const ContentPanel = ( { className, children } ) => (

  <Wrap className={className}>
    <Content>
      {children}
    </Content>
  </Wrap>

)

export default ContentPanel

export const PanelGreen = styled(ContentPanel)`
  background-color: ${({ theme }) => theme.colorResetGreen};
  background-image: url(${shapeWhite});
  background-size: contain;  
`

export const PanelWhite = styled(ContentPanel)`
  background-color: ${({ theme }) => theme.colorResetWhite};
  background-image: url(${shapeWhite});
  background-size: contain;
`

export const PanelRed = styled(ContentPanel)`
  background-color: ${({ theme }) => theme.colorResetRed};
  background-image: url(${shapeWhite});
  background-size: contain;
  color: #fff;
`

export const PanelYellow = styled(ContentPanel)`
  background-color: ${({ theme }) => theme.colorResetYellow};
  background-image: url(${shapeWhite});
  background-size: contain;
`

export const PanelBlue = styled(ContentPanel)`
  background-color: ${({ theme }) => theme.colorResetBlue};
  background-image: url(${shapeWhite});
  background-size: contain;
`
