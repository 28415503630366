import React from "react"
import styled, { keyframes } from 'styled-components'

const breatheAnimation = keyframes`
 0% { height: 237px;  }
 50% { height: 250px;  }
 100% { height: 237px;  }
`

const Wrap = styled.div `
  display: none;

  @supports(clip-path: polygon(50% 0%, 0% 100%, 100% 75%)) {
    display: block;
  }

  /* filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.3)); */
  animation-name: ${breatheAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;  
`

const Triangle = styled.div `
  background-color: ${({ theme }) => theme.colorResetBlue};
  clip-path: polygon(50% 0%, 0% 100%, 100% 75%);
  height: 237px;
  width: 182px;
`

const ShapeTriangle = () => (

  <Wrap>
    <Triangle />
  </Wrap>

)

export default ShapeTriangle
