import React from "react"
import styled from "styled-components"

const Quotation = styled.h2`
  color: ${({ theme }) => theme.colorResetBlue};
  font-size: ${({ theme }) => theme.FontLarge};
  margin-bottom: 2.8125rem;
  padding: 0 10%;
`

const Author = styled.p`
  color: ${({ theme }) => theme.colorResetBlue};
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.3;
`

const Quote = ({ author, quote }) => (

  <div>
    <Quotation><q>{quote}</q></Quotation>
    <Author>{author}</Author>
  </div>

)

export default Quote
