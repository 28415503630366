import React from "react"
import styled, { keyframes } from 'styled-components'

const rectangleAnimation = keyframes`
 0% { height: 180px;  }
 50% { height: 190px;  }
 100% { height: 180px;  }
`

const RectangleWrap = styled.div `
  display: none;
  
  @supports(clip-path: polygon(1% 23%, 100% 1%, 99% 65%, 14% 100%)) {
    display: block;
  }

  /* filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.3)); */
  animation-name: ${rectangleAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;  
`

const Rectangle = styled.div `
  background-color: ${({ theme }) => theme.colorResetBlue};
  clip-path: polygon(1% 23%, 100% 1%, 99% 65%, 14% 100%);
  height: 180px;
  width: 180px;
`

const ShapeRectangle = () => (

  <RectangleWrap>
    <Rectangle />
  </RectangleWrap>

)

export default ShapeRectangle
